.component-spinwheel {

  .row {
    display: flex;
  }

  .item {
    box-sizing: border-box;
    border: 1px solid transparent;

    &.--highlight {
      opacity: 0.4;
    }

    &.--empty {
      img {
        opacity: 0;
      }
    }

    &.--flex-grow-4 {
      flex-grow: 5;
    }

    .score-label {
      margin: 0 auto;
      font-size: 0.5em;
      box-sizing: border-box;
    }
  }

  .game-panel {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .image-container {
      padding-top: 4px;
      text-align: center;
    }

    .label-panel {
      display: flex;
      justify-content: space-around;
      padding-bottom: 12px;

      .label-container {
        text-align: center;
        align-items: center;

        .label {
          color: white;
          font-size: 12px;
          margin: 0 4px;
          padding-bottom: 4px;
          text-align: center;
          font-family:  'open sans';
          font-style: bold;
        }

        span {
          color: darkmagenta;
          background: white;
          font-size: 13px;
          font-family:  'open sans';
          font-style: bold;
          padding: 0 8px;
          border-radius: 5px;
          width: 30px;
        }
      }
     
    }

    .spin-btn {
      outline: none;
      border: none;
      background: none;

      .spin-logo {
        animation: scaling 1.5s infinite;
  
        &.--hover {
          opacity: 0.4;
        }
      }
    }

    .spin-btn:active:enabled {
      opacity: 0.4;
      transform: translateY(4px);

    }

    .spin-btn:disabled {
      opacity: 0.4;
    }

    @media screen and (max-width: 400px) {
      .spin-logo {
        width: 50px;
      }
    }

    @media screen and (max-width: 576px) {
      .spin-logo {
        width: 70px;
      }
    }

    @media screen and (min-width: 577px) {
      .spin-logo {
        width: 100px;
      }
    }

    @media screen and (min-width: 768px) {
      .spin-logo {
        width:  125px;
      }
    }
    
  }

  td {
    vertical-align: middle;
  }

  @keyframes scaling {
    0% {
      transform: scale(1.0);
    }
      
    50% {
      transform: scale(0.8);
    }

    100% {
      transform: scale(1.0);
    }
  }
  
}