.component-download {
  display: flex;
  position: fixed;
  right: -5px;
  bottom: 100px;
  height: 30px;
  padding: 4px 8px;
  background: linear-gradient(to bottom,#ffe102 0%, #feb700 100%);
  transform: rotate(-90deg);
  text-decoration: none;
  color: inherit;
  z-index: 50;

  .icon {
    transform: rotate(90deg);
    width: 30px;
    display: inline-block;
  }

  .icon img{
    // border-radius: 50%;
    // background: gold;
  }
}