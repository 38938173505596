.component-live-chat {
  display: flex;
  position: fixed;
  right: -50px;
  bottom: 200px;
  height: 30px;
  padding: 4px 8px;
  background: linear-gradient(to bottom,#ffe102 0%, #feb700 100%);
  transform: rotate(-90deg);
  text-decoration: none;
  color: inherit;
  z-index: 50;

  span {
    display: inline;
    padding: 8px 4px;
    box-sizing: border-box;
    font-size: 11px;
    font-weight: 800;
    font-style: italic;
  }

  .icon {
    transform: rotate(90deg);
    display: inline-block;
    width: 30px;
  }
}