.page-deposit {
  .footer {
    text-align: center;
    color: black;
  }

  .warn-bonus {
    padding: 8px;
    margin-bottom: 8px;
  }

  .warn-color {
    color: red;
    font-size: 16px;
  }

  .warn-bold {
    font-weight: bold;
  }
}