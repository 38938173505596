@import '../../framework/utils/variables';
@import '../../framework/utils/static';
@import '../../framework/utils/shadow';

.scope-app {
  background-color: #e8e8e8;
  font-family: $base-font;
  position: relative;
  min-height: 100vh;
  background-color: #c035ff;
  background: linear-gradient(to bottom, #1e0829 0%,#c035ff 35%,#c035ff 35%,#1e0829 100%);

  .transparent {
    opacity: 0;
  }

  .badge {
    background-color: #fa3e3e;
    border-radius: 10px;
    color: white;
    font-size: 10px;
    vertical-align: middle;
    z-index: 2;
    position: absolute;
  }
}

@media only screen and (max-width: 980px) {
   #tidio-chat-iframe {
    bottom: 72px !important;
    right: -3px !important;
  }
}