.page-login {

  .header {
    background-color: #123123;
    color: white;
    padding: 16px;

    img {
      display: block;
      margin: 0 auto;
    }

    h1 {
      font-size: 22px;
      text-align: center;
      font-weight: 600;
      letter-spacing: 5px;
      text-transform: uppercase;
      padding: 22px 0;
    }
  }

  .title {
    position: relative;
    text-align: center;
    color: #fff;
    z-index: 100;
    margin-top: 8px;
    margin-bottom: 40px;
  }

  .page-decorator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 300%;
    border-bottom-right-radius: 300%;
    background: linear-gradient(to right, #72018c 0%, #6640b9 100%);
    z-index: 10;
  }

  .input-element {
    padding: 8px;
    box-sizing: border-box;

    label {
      font-size: 12px;
      margin-bottom: 6px;
      letter-spacing: 2px;
      display: block;
      text-transform: uppercase;
      font-weight: 600;
    }

    input {
      display: block;
      width: 100%;
      padding: 6px 12px;
      font-size: 16px;
      letter-spacing: 2px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      outline: none;
      transition: all 0.5s cubic-bezier(.25,.8,.25,1);
    }

    input:focus {
      border: 1px solid rgba(45, 67, 200, 0.5);
    }
  }
}