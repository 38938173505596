.component-real-time-board {
  h2.header {
    text-align: center;
    color: #feb700;
    font-style: italic;
    margin-bottom: 16px;
  }

  h3.header, p.announce{
    text-align: center;
    color: white;
  }

  h3.header.warn {
    color: red;
  }

  p.description {
    color: white;
  }

  table {
    width: 100%;
  }

  td, th {
    color: white;
    border-bottom: 1px solid #feb700;
    padding: 0.5rem;
    text-align: left;
    vertical-align: middle;
    font-size: 16px;
  }

  td {
    height: 80px;
  }
  
  .text-center {
    text-align: center;
  }

  .vertical-middle {
    vertical-align: middle;
  }
  
  .icon-wrapper {
    width: 30px;
    display: inline-block;
    margin-right: 8px;
    // margin: 8px auto;
  }

  .row-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
    font-size: 1.1rem;
  }

  .hours {
    color: white;
  }
  
  .percentage,
  .percentage {
    text-align: center;
    cursor: pointer;
  }

  .percentage:active,
  .percentage:active {
    text-decoration: underline;
  }

  .percentage-up {
    color: lightgreen;
  }

  .percentage-down {
    color: lightcoral;
  }

  .fade-animation {
    animation: fadeInOut 3s ease-in-out infinite;
    animation-delay: 2s;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0.1;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.1;
    }
  }

  .branded {
    border: 1px solid #feb700;
  }

  @media screen and (max-width: 768px) {
    td, th {
      text-align: center;
      font-size: 16px;
    }
 
    .icon-wrapper {
      display: inline-block;
      margin: 8px auto;
    }

    .game-text {
      display: block;
    }
  }

  @media screen and (max-width: 413px) {
    th {
      font-size: 14px;
    }

    td {
      font-size: 14px
    }
 
  }

 

}