.component-auth-prompt {

  .header {
    text-decoration: none;
    color: inherit;

    .__logo {
      width: 180px;
      margin: 0 auto;
    }

    .__text {
      display: block;
      text-align: center;
      font-size: 10px;
      color: #fff;
      margin: 8px 0;
      font-weight: 800;
      font-style: italic;
    }
 
  }

  .__home{
    position: absolute;
    top: 70px;
    padding: 0 8px;

    .__gold-icon {
      color: #ffc600;
      font-size: 22px;
    }

    .__gold-icon-text {
      font-size: 10px;
      color: #fff;

    }
  }

  .__language {
    position: absolute;
    top: 70px;
    right: 8px;
    text-align: center;
    align-items: flex-end;

    .__text {
      margin: 4px;
      color: #fff;
      font-size: 10px;
    }

    .__flag-selected {
      width: 28px;
    }
  }

  .header-image {
    width: 100%;
  }


  .call-to-action-container {
    width: 100%;
    position: absolute;
    bottom: 8px;
    left: 0;

    .__cta {
      justify-content: center;
    }
  }

  .wallet-balance {
    position: absolute;
    top: 60px;
  }

  .download-btn {
    margin: 0 25%;
  }
}